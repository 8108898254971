// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaMyArea } from '@/router/guards/init-nav-area';

export const signupRoutes: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: 'signup',
            name: Navigation.Signup,
            props: (route) => ({
                queryEmail: route.query.email,
                queryCompanyId: route.query.companyId,
            }),
            component: async () => import('../views/SignupSelection.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.SIGNUP',
            },
        },
        {
            path: 'signup/new',
            name: Navigation.SignupNew,
            component: async () => import('../views/SignupView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.SIGNUP_NEW',
            },
        },
        {
            path: 'signup/new/success',
            name: Navigation.SignupNewSuccess,
            component: async () => import('../views/SignupSuccessView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.SIGNUP_SUCCESS',
            },
        },
        {
            path: 'signup/fast-track/success',
            name: Navigation.SignupFastTrackSuccess,
            props: (route) => ({
                isNew: route.query.isNew,
            }),
            component: async () => import('../views/FastTrackSignupSuccessView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.FAST_TRACK_REGISTRATION_SUCCESS',
            },
        },
        {
            path: 'signup/activate/new',
            props: (route) => ({
                token: route.query.token,
                email: route.query.email,
            }),
            beforeEnter: [
                (to, from, next) => {
                    const token = to.query.token;
                    const email = to.query.email;
                    if (token == null || email == null) {
                        next(Navigation.NotFound);
                    } else {
                        next();
                    }
                },
                initNavAreaMyArea,
            ],
            name: Navigation.SignupActivateNew,
            component: async () => import('../views/NewSignupCustomerActivateView.vue'),
            meta: {
                titleKey: 'TITLES.SIGNUP_ACTIVATE',
            },
        },
        {
            path: 'signup/activate/fast-track',
            props: (route) => ({
                token: route.query.token,
                email: route.query.email,
            }),
            beforeEnter: [
                (to, from, next) => {
                    const token = to.query.token;
                    const email = to.query.email;
                    if (token == null || email == null) {
                        next(Navigation.NotFound);
                    } else {
                        next();
                    }
                },
                initNavAreaMyArea,
            ],
            name: Navigation.SignupActivateFastTrack,
            component: async () => import('../views/CustomerActivateView.vue'),
            meta: {
                titleKey: 'TITLES.ACTIVATE_CUSTOMER',
            },
        },
        {
            path: 'signup/activate/success',
            name: Navigation.SignupActivateSuccess,
            component: async () => import('../views/ActivateCustomerSuccessView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.SIGNUP_ACTIVATE_SUCCESS',
            },
        },
    ],
};
